import { graphql, Link } from "gatsby"
import React, { Component } from "react"
import "@babel/polyfill";
import PropTypes from "prop-types";

import Default from './default'; 

import "../css/single-who-we-serve.css"

import YoastSeoParts from '../fragments/seo';

class SingleSolution extends Component {
  render() {
    // Destructuring page data from the props
    const { wpSolution, allWpPost, wp, allWpGfForm } = this.props.data;

    // Destructuring values from wpPage
    const { seo, template } = wpSolution;
    const { pageOptions } = template;
    const theContent = template.templateDefault.content;
    const imageHero = template.templateDefault;
    const pageTitle = wpSolution.title;


    // Destructuring specific values for the pageData
    const { section: rawSection, removeSignup: noSignUp } = pageOptions;
    const section = rawSection ?? "solutions"; // Only replaces null or undefined
    const { phoneLink } = wp.acfOptionsContact.themeOptionsContact;

    // Prepare pageData object to pass to Default component
    const pageData = {
      seo,
      pageOptions,
      pageTitle,
      theContent,
      articles: allWpPost.nodes,
      section,
      noSignUp,
      phoneLink,
      imageHero,
      allWpGfForm,
    };

    return (
      <div className="solution">
        <Default pageData={pageData} />
      </div>
    );
  }
}

// PropTypes for validation
SingleSolution.propTypes = {
  data: PropTypes.shape({
    wpSolution: PropTypes.object.isRequired,
    allWpPost: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
    wp: PropTypes.shape({
      acfOptionsContact: PropTypes.shape({
        themeOptionsContact: PropTypes.shape({
          phoneLink: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    allWpGfForm: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SingleSolution;


export const query = graphql`
  query($id: String!, $articlesCategoryId: String = "//") {
     wpSolution(id: { eq: $id }) {
      title
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
      template {
        ... on WpDefaultTemplate{
          templateDefault {
            backgroundImage {
              altText
              localFile {
                publicURL
              }
            }
            frontImage {
              altText
              localFile {
                publicURL
              }
            }
            headline
            buttons {
              buttonLabel
              buttonType
              link {
                url
                target
              }
            }
            
            content {
              __typename
              ... on WpDefaultTemplate_Templatedefault_Content_OneColumn {
                oneColumn{
                __typename
                  ... on WpDefaultTemplate_Templatedefault_Content_OneColumn_OneColumn_TextBlock {
                    textBlockContent
                    textBlockHeadline
                    textBlockHaveSuper
                    textBlockSuper
                    textBlockCtaButtons {
                      buttonLabel
                      buttonType
                      link {
                        target
                        url
                      }     
                    }
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_OneColumn_OneColumn_Form {
                    formContent
                    formFormId
                    formHeadline
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_OneColumn_OneColumn_Calculator {
                    calculatorCalculatorType
                    calculatorDisclaimer
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_OneColumn_OneColumn_Image{
                    imageImage {
                      altText
                      localFile {
                        publicURL
                      }
                    }
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_OneColumn_OneColumn_Html {
                    flexibleContent
                  }  
                  ... on WpDefaultTemplate_Templatedefault_Content_OneColumn_OneColumn_Faqs {
                    faqCategories {
                      id
                      name
                      contentNodes {
                        nodes {
                          ... on WpFaq {
                            id
                            title
                            link
                            excerpt
                          }
                        }
                      }
                    }
                  }
                }

               alignColumn
               columnWidth
               backgroundColor 
              }
              ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn {
                backgroundColor
                columnSplit
                leftColumn{
                __typename
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_LeftColumn_TextBlock {
                    textBlockContent
                    textBlockHeadline
                     textBlockHaveSuper
                    textBlockSuper
                    textBlockCtaButtons {
                      buttonLabel
                      buttonType
                      link {
                        target
                        url
                      }     
                    }
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_LeftColumn_Form {
                    formContent
                    formFormId
                    formHeadline
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_LeftColumn_Calculator {
                    calculatorCalculatorType
                    calculatorDisclaimer
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_LeftColumn_Image{
                    imageImage {
                      altText
                      localFile {
                        publicURL
                      }
                    }
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_LeftColumn_Html {
                    flexibleContent
                  }     
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_LeftColumn_Faqs {
                      faqCategories {
                        id
                        name
                        contentNodes {
                          nodes {
                            ... on WpFaq {
                              id
                              title
                              link
                              excerpt
                            }
                          }
                        }
                      }
                    }     
                }
                rightColumn{
                __typename
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_RightColumn_TextBlock {
                    textBlockContent
                    textBlockHeadline
                     textBlockHaveSuper
                    textBlockSuper
                    textBlockCtaButtons {
                      buttonLabel
                      buttonType
                      link {
                        target
                        url
                      }     
                    }
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_RightColumn_Form {
                    formContent
                    formFormId
                    formHeadline
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_RightColumn_Calculator {
                    calculatorCalculatorType
                    calculatorDisclaimer
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_RightColumn_Image{
                    imageImage {
                      altText
                      localFile {
                        publicURL
                      }
                    }
                  }
                  ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_RightColumn_Html {
                  flexibleContent
                  }     
                   ... on WpDefaultTemplate_Templatedefault_Content_TwoColumn_RightColumn_Faqs {
                    faqCategories {
                      id
                      name
                      contentNodes {
                        nodes {
                          ... on WpFaq {
                            id
                            title
                            link
                            excerpt
                          }
                        }
                      }
                    }
                  }  
                } 
              }
          
              ... on WpDefaultTemplate_Templatedefault_Content_ThreeColumn {
                leftHeadline
                leftCopy
                leftImage {
                  altText
                  localFile {
                    publicURL
                  }
                }
                leftButtons {
                  buttonLabel
                  buttonType
                  link {
                    url
                    target
                  }
                }
                middleHeadline
                middleCopy
                middleImage {
                  altText
                  localFile {
                    publicURL
                  }
                }
                middleButtons {
                  buttonLabel
                  buttonType
                  link {
                    url
                    target
                  }
                }
                rightHeadline
                rightCopy
                rightImage {
                  altText
                  localFile {
                    publicURL
                  }
                }
                rightButtons {
                  buttonLabel
                  buttonType
                  link {
                    url
                    target
                  }
                }
              }
              ... on WpDefaultTemplate_Templatedefault_Content_QuoteSlider {
                largeTestimonialSlider {
                  ... on WpTestimonial {
                    id
                    content
                    cptSingleTestimonial {
                      firstName
                      lastName
                      position
                      testimonial
                      signature {
                        localFile {
                          publicURL
                        }
                      }
                    }
                    featuredImage {
                      node {
                        localFile {
                          publicURL
                        }
                      }
                    }
                  }
                }
              }
              ... on WpDefaultTemplate_Templatedefault_Content_Articles {
                articleTitle
                articles {
                  wpURI
                }
              }
             ... on WpDefaultTemplate_Templatedefault_Content_SolutionsGrid {
                backgroundColor
                component
                textBlockContent
                textBlockHaveSuper
                textBlockHeadline
                textBlockSuper
                textBlockCtaButtons {
                  buttonLabel
                  buttonType
                  link {
                    target
                    url
                  }
                }
              }   
              ... on WpDefaultTemplate_Templatedefault_Content_SmallTextGrid {
                backgroundColor
                boxColor
                 components {
                ... on WpSolution {
                  link
                  title
                }
                ... on WpWhoWeServe {
                  link
                  title
                } 
                }        
              }
              ... on WpDefaultTemplate_Templatedefault_Content_LargeTextGrid {
                backgroundColor
                boxColor
                hoverColor
                component
                textBlockContent
                textBlockHaveSuper
                textBlockHeadline
                textBlockSuper
                textBlockCtaButtons {
                  buttonLabel
                  buttonType
                  link {
                    target
                    url
                  }
                }
              }   
            }
          }
          pageOptions {
            section
            removeSignup
          }
        }
      }
    }
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { regex: $articlesCategoryId } } } } }
      sort: { order: DESC, fields: date }
      limit: 10
    ) {
      nodes {
        id
        title
        link
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
    wp {
      acfOptionsContact {
        themeOptionsContact {
          contactUsCopy
          phoneText
          phoneLink
          caHeadquarters {
            address
            address2
            city
            faxNumber
            mapIframe
            phoneLink
            phoneText
            postalCode
            province
          }
          usHeadquarters {
            address
            address2
            city
            faxNumber
            mapIframe
            phoneLink
            phoneText
            state
            zip
          }
        }
      }
     
     
    }
       allWpGfForm {
        nodes {
        ...GravityFormFields
        }
      }
  }
`;
